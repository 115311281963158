@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary: #075f40;
$secondary: #043927;

$custom-theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "positive": $blue,
  "neutral": $gray-600
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

$modal-sm:                          500px;
$modal-md:                          750px;
$modal-lg:                          1000px;
$modal-xl:                          1250px;

@import "~bootstrap/scss/bootstrap";

.dropdown-item {
  &.active,
  &:active {
    color: $dropdown-link-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    @include gradient-bg($dropdown-link-hover-bg);
  }
}

.badge {
  font-size: .75rem;
}

.form-label.required:after {
  content:"*";
  margin-left: 2px;
  color:red;
}

.form-check {
  display: flex;
  align-items: center;

  .form-check-label {
    margin-left: .6rem;
  }
}

.form-switch .form-check-input:focus,
.form-check .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba($primary, .25);
  border-color: rgba($primary, .35);
}

.form-switch .form-check-input:checked,
.form-check .form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.tab-content {
  padding: 1rem;
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgba($primary, .25);
}

.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

}

